import React, {Fragment, useEffect, useState} from 'react'
import RegularHero from '../components/Hero-Section/RegularHero';
import UncuttedImg from '../components/Uncutted Image Content/UncuttedImg';
import MLBAthlete from '../components/MLB Athletes/MLBAthlete';
import Factors from '../components/Important Factors/Factors';
import QualityExp from '../components/Quality Experience/QualityExp';
import CoachAssociations from '../components/Coaching Associations/CoachAssociations';
import RadialImgContent from '../components/Radial Image Content/RadialImgContent';
import Loader from '../components/Loader/Loader';
import MembershipSlider from '../components/Memberships/MembershipSlider';
import { SpeedTrainingMembershipsArr } from '../components/Constants/ElementaryMemberships';
import { Helmet } from 'react-helmet';

const SpeedTraining = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const renderImageBasedOnMode = (currImage) => { 
    if(currImage == '/images/creatives/banner_slider_1.png' && window.innerWidth <= 620) return '/images/creatives/banner_slider_mob_1.png'; 
    return currImage;
  };

  const SpeedTrainingModule = () => {
    return (
      <Fragment>
      <Helmet>
        <title>Best Speed Training Program in Ontario</title>
        <meta name="keywords" content="Speed Training Program in Ontario"/>
        <link rel="canonical" href="https://www.questsportscanada.club/speed-training-program " />
        <meta name="description" content=" Want to improve your speed and agility on the field or court Our speed training programs in Ontario are specialized to help you gain an edge over the competition"/>
      </Helmet>
        <div className='quest_sp_speed_Training_page'>
          <RegularHero 
            backgroundImage= {renderImageBasedOnMode('/images/creatives/banner_slider_1.png')}  
            highlightedText={'REGISTRATION IS NOW OPEN'} 
            text={'SPEED TRAINING'}  
            breakLine={true}
            endHighlightedText={true}
            paraSpeedTraining={true}
          />
          <UncuttedImg  
            programsRoute={true}
            colPaddingTop='0' 
            // listItemSpeed={SpeedTrainingListItems}
            buttonTextStr={'Sign Up'} 
            speedTraining={true} 
            targetSection={'#speed_training_membership_slider'} 
            buttonStr={true}
            imgURL={`/images/creatives/uncutted-img-content-2.png`} 
          />
          <MLBAthlete />
          <RadialImgContent 
            heading={`Join Quest Sports Track and Field Club and Experience Quality Athletic Development`}
            paragraph={`The Quest Professional Athletic Group founded Quest Sports Inc. The group is comprised of current and former professional athletes, to Olympians, 
            who unanimously believe that Canada has yet to tap into its true athletic talents. In an effort to introduce the Quest Training Systems to the world of 
            sport, Quest Sports Inc. has set forth a bold and passionate plan to identify sports talent in children from the playgrounds of Canada. 
            Our goal is to take them from their humble beginnings in the playground to the realization of their true athletic potential.`}
            buttonTitle={`Sign Up`}
            buttonRoute={'/appointment-booking'}
            targetSection={'#speed_training_membership_slider'}
            removeBtn={true} 
            programsRoute={true} 
            buttonIcon='/images/icons/golden-torch.svg'
            hoverBtnIcon='/images/icons/black-torch.svg'
            imgUrl={`/images/creatives/teenage-girl-professional-runner.png`}
          />
          <UncuttedImg 
            topHeading={true} 
            targetSection={'#speed_training_membership_slider'}
            buttonStr={true} 
            colPaddingTop='0'  
            buttonTextStr={'Sign Up'} 
            speedTraining2={true} 
            imgURL={`/images/creatives/medals.png`}
          />
          <Factors />
          <MembershipSlider 
            id={'speed_training_membership_slider'} 
            backgroundImage={`/images/creatives/track-field-highschool-membership.png`} 
            membershipsType={SpeedTrainingMembershipsArr} 
            mainHeading={'SPEED TRAINING MEMBERSHIPS'} 
          />
          <QualityExp targetSection={'#speed_training_membership_slider'} />
          <CoachAssociations 
            heading={`Coaching ASSOCIATES`} 
            imgURL1={`/images/creatives/coach-1.png`} 
            imgURL2={`/images/creatives/coach-2.png`} 
          />
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {loading ? <Loader />:  SpeedTrainingModule()}
    </Fragment>
  )
}

export default SpeedTraining;