export const TrainingStudents = [
    {id: 1, img: '/images/creatives/training-1.png'},
    {id: 2, img: '/images/creatives/training-2.png'},
    {id: 3, img: '/images/creatives/training-3.png'},
    {id: 4, img: '/images/creatives/training-4.png'},
    {id: 5, img: '/images/creatives/training-5.png'},
    {id: 6, img: '/images/creatives/training-6.png'},
    {id: 7, img: '/images/creatives/training-7.png'},
    {id: 8, img: '/images/creatives/training-8.png'},
    {id: 9, img: '/images/creatives/training-9.png'},
]
 