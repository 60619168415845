import React, {useState, useEffect, Fragment} from 'react'
import RegularHero from '../components/Hero-Section/RegularHero'
import Loader from '../components/Loader/Loader';
import Contact from '../components/Get In Touch/Contact';
import RadialImgContent from '../components/Radial Image Content/RadialImgContent';
import Associations from '../components/Associations Slider/Associations';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const ContactModule = () => {
    return (
      <Fragment>
        <Helmet>
          <title>Start your athletic journey today</title>
          <meta name="description" content="Contact Quest Sports Academy for inquiries, partnerships, and more. Our dedicated team is ready to assist you. Reach out today and let's embark on a journey to excellence together." />
        </Helmet>
        <div className='quest_sp_contact_page'>
          <RegularHero 
            endHighlightedText={true}
            highlightedText={`US`}
            backgroundImage= {'/images/creatives/contact-hero.png'}
            text={'CONTACT'} 
          />
          <Contact />
          <RadialImgContent 
            contact={true}
            heading={`Join Quest Sports Track and Field Club and Experience Quality Athletic Development`}
            paragraph={`The Quest Professional Athletic Group founded Quest Sports Inc. The group is comprised of current and former professional athletes, to Olympians, 
            who unanimously believe that Canada has yet to tap into its true athletic talents. In an effort to introduce the Quest Training Systems to the world of 
            sport, Quest Sports Inc. has set forth a bold and passionate plan to identify sports talent in children from the playgrounds of Canada. 
            Our goal is to take them from their humble beginnings in the playground to the realization of their true athletic potential.`}
            buttonTitle={`Inquire Now`}
            buttonRoute={'/appointment-booking'}
            buttonIcon='/images/icons/golden-torch.svg'
            hoverBtnIcon='/images/icons/black-torch.svg'
            imgUrl={`/images/creatives/teenage-girl-professional-runner.png`}
          />
          <Associations translate='X' />
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {loading ? <Loader /> : ContactModule()}
    </Fragment>
  )
}

export default ContactUs;