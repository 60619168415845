import React, { Fragment, useEffect, useState } from 'react'
import RadialImgContent from '../components/Radial Image Content/RadialImgContent'
import RegularHero from '../components/Hero-Section/RegularHero'
import Loader from '../components/Loader/Loader'
import CoachAssociations from '../components/Coaching Associations/CoachAssociations'
import Associations from '../components/Associations Slider/Associations'
import TrainingHub from '../components/Training Hub/TrainingHub'
import Gallery from '../components/Gallery/Gallery'
import { GalleryImages, TrainingStudents } from '../components/Training Hub/Students'
import Accomplishments from '../components/Accomplishments/Accomplishments'
import { Helmet } from 'react-helmet'
import SocialMedia from '../components/Blogs/SocialMedia'

const Events = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
        setLoading(false);
        }, 500);
    }, []);
 
    const EventsModule = () => {
        return (
          <Fragment>
          <Helmet>
            <title>Stay tuned with our track and field club</title>
            <meta name="keywords" content="News"></meta>
            <meta name="description" content="Stay up-to-date with the latest news and updates from Quest Sports Academy. Discover inspiring stories, groundbreaking achievements, and exciting events that showcase the passion and dedication of our athletes" />
          </Helmet>
          <div className='quest_sp_events_page'>
            <RegularHero 
              backgroundImage= {'/images/creatives/events-hero.png'}
              text={'NEWS'}  
            />
            <SocialMedia />  
            <TrainingHub /> 
            <CoachAssociations 
              imgURL1={'/images/creatives/coach-1.png'}
              imgURL2={'/images/creatives/coach-2.png'} 
              heading={'Coaching ASSOCIATES'} 
            />
            <RadialImgContent 
              heading={`Join Quest Sports Track and Field Club and Experience Quality Athletic Development`}
              paragraph={`The Quest Professional Athletic Group founded Quest Sports Inc. The group is comprised of current and former professional athletes, to Olympians, 
              who unanimously believe that Canada has yet to tap into its true athletic talents. In an effort to introduce the Quest Training Systems to the world of 
              sport, Quest Sports Inc. has set forth a bold and passionate plan to identify sports talent in children from the playgrounds of Canada. 
              Our goal is to take them from their humble beginnings in the playground to the realization of their true athletic potential.`}
              buttonTitle={`Inquire Now`}
              buttonRoute={'/appointment-booking'}
              buttonIcon='/images/icons/golden-torch.svg'
              hoverBtnIcon='/images/icons/black-torch.svg'
              imgUrl={`/images/creatives/teenage-girl-professional-runner.png`}
            /> 
            <Accomplishments backgroundImage={'/images/creatives/accomplishment-banner.png'} />
            <RadialImgContent 
              heading={`Quest Sports Performance Merchandise`}
              // anotherParagraph={`Weather an athlete, parent or a fan, wearing a shirt, hoodie with is a great way of promoting team spirit.`}
              paragraph={`Wearing Quest Sports apparel is a great way to show support for our dedicated athletes. Family and fans alike can promote team spirit by wearing  our branded hoodies and T-shirts.`}
              buttonTitle={`SHOP NOW`} 
              hoverBtnIcon={`/images/icons/cart-black.png`}
              imgUrl={`/images/creatives/Shirt.png`}
              buttonIcon={`/images/icons/cart.svg`}
            />
          </div>
        </Fragment>
      )
    }
  return (
    <Fragment>
       {loading ? <Loader /> : EventsModule()}
    </Fragment>
  )
}

export default Events;