import React from 'react';
import './MembershipSlider.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper/core';

SwiperCore.use([Autoplay, Navigation, Pagination]); 

const MembershipSlider = ({mainHeading, id, backgroundImage, membershipsType}) => {
    return (
        <div className="membership-slider" id={id} style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className="container">
                <div className='memberships_info text-center mb-5'>
                    <h1>{mainHeading}</h1>
                </div>
                <div className='text-center'>
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={3}
                        loop={true}
                        autoplay={{ delay: 1000 }}
                        className='membership-swiper'
                        speed={500} 
                        pagination={{ clickable: true }}
                        breakpoints={{
                            280: {
                                slidesPerView: 1,
                            },
                            340: {
                                slidesPerView: 1,
                            },
                            440: {
                                slidesPerView: 1,
                            },
                            540: {
                                slidesPerView: 1,
                            },
                            600: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {membershipsType.map((card) => (
                            <SwiperSlide key={card.id}>
                                <div className={`card`}>
                                    <div className='row text-center'>
                                        <div className='col-12 mb-2'> 
                                            <img src={card.icon} className='img-fluid icon' />
                                            <h1 className='title'>{card.title}</h1>
                                            <h2 className='sub-title'>{card.subTitle}</h2>
                                        </div> 
                                        <div className='col-12'>
                                           <div className='action-triggerer'> <a href={card.registrationLink} target='_blank' rel='noopener noreferrer'><button className='skew-btn'>Register Now</button></a> </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default MembershipSlider;
