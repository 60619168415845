import React, {useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Footer.css';
import { Settings } from '../../inc/Settings';

const Footer = () => {  
  const [formData, setFormData] = useState({
    email: ''
  }) 
  const navigate = useNavigate()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenCommunity, setDropdownOpenCommunity] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [btnHover, setBtnHover] = useState(false)
  
  let subsAPIFired = false

  const toggleDropdown = () => { setDropdownOpen(!dropdownOpen) }

  const toggleDropdownCommunity = () => { setDropdownOpenCommunity(!dropdownOpenCommunity) }
  
  const NavigateToPrograms = () => { navigate('/programs') }

  const EmailHandler = (evt) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        email: evt.target.value
      }
    })
  }

  const SubscribeHandler = () => {
    if(!formData.email){
      Settings.alert('Email field missing!', 'error')
      return;
    }

    subsAPIFired = true 

    const accountSid = 'AC51a1cb5148f2e4ea95791a5a4733d692';
    const authToken = '51d9f20f4ef028663c9c187418f14676';
    const fromNumber = '+14346235312';
    const toNumber = '+16477709124';
    const body = 
    `This User Just Subscribed To Us!;
      eMail: ${formData.email}

      ----
      Message from Quest Sports Club Canada.
    `;

    const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${btoa(`${accountSid}:${authToken}`)}`,
      },
      body: new URLSearchParams({
        From: fromNumber,
        To: toNumber,
        Body: body,
      }),
    })

    .then(response => { 
      Settings.alert('Message sent successfully!')
      subsAPIFired = true 
      setFormData({
        email: ''
      })
    })
    .catch(error => {
      Settings.alert('Message couldnt Process!', 'error')
      subsAPIFired = false 
    });
  }

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  return (
    <footer className='footer_component'>
      <div className="container">
        <div className="row p-2">
          <div className="col-lg-3 col-md-12 col-sm-12 mb-5">
            <div className='row d-flex flex-lg-column align-items-center'>
              <div className='col-7 col-lg-12 text-lg-center'>
                <Link to='/home'> 
                  <img className='img-fluid company-logo' src='/images/questSports.svg' />
                </Link>
              </div>
              <div className='col-5 col-lg-12 text-lg-start'> 
                <img className='img-fluid certified-logo' src='/images/icons/olympian.png' />  
              </div>
            </div>
          </div>
          <div className="col-lg-2 mb-3 col-6 footer-links"> 
            <ul>
              <li><Link to='/home'>HOME</Link></li>
              <li><Link to='/about'>ABOUT</Link></li> 
              <li onClick={toggleDropdownCommunity}>
                COMMUNITIES
                <span><img className='img-fluid expand-icon' src='/images/icons/expand.svg' /></span>
                {dropdownOpenCommunity && (  
                  <ul>
                    <li><a href="https://app.360player.com/join/TKTTG4" target='_blank' rel='noopener noreferrer'>JOIN COMMUNITY</a></li>
                    <li><a href="https://app.360player.com" target='_blank' rel='noopener noreferrer'>MEMBERS LOGIN</a></li> 
                  </ul> 
                )}
              </li>
              <li><Link to='/contact-us'>CONTACT US</Link></li> 
            </ul>
          </div>
          <div className="col-lg-2 col-6 mb-3 footer-links"> 
            <ul>
              <li onClick={toggleDropdown} onDoubleClick={NavigateToPrograms}>
                PROGRAMS
                <span><img className='img-fluid expand-icon' src='/images/icons/expand.svg' /></span>
                {dropdownOpen && (  
                  <ul>
                    <li><Link to='/track-field-club'>Track & Field</Link></li>
                    <li><Link to='/speed-training-program'>Speed Training</Link></li>
                    <li><Link to='/strength-conditioning-gym'>Strength & Conditioning</Link></li>
                  </ul> 
                )}
              </li>
              <li><Link to={`/blogs`}>BLOGS</Link></li>
              <li><Link to={'/news'}>NEWS</Link></li>
            </ul>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 company-info mb-3">  
            <ul>

              <div className="location container m-0 mt-2">
                <div className='row d-flex align-items-center'>
                  <div className='col-1 p-0 m-0'>
                    <img className='img-fluid' src='/images/icons/email.svg' />
                  </div>
                  <div className='col-10'>
                    <span>questsportscanada@gmail.com</span> 
                  </div>
                </div>
              </div>

              <div className="location container m-0 mt-4">
                <div className='row d-flex align-items-center'>
                  <div className='col-1 p-0 m-0'>
                    <img className='img-fluid' src='/images/icons/phone.svg' />
                  </div>
                  <div className='col-10'>
                    <span><a href='tel: +1-647-557-9880'>+1-647-557-9880</a></span> 
                  </div>
                </div>
              </div>

              <div className="location container m-0 mt-3">
                <div className='row d-flex align-items-center'>
                  <div className='col-1  p-0 m-0'>
                    <img className="img-fluid " src="/images/icons/location.svg" />  
                  </div>
                  <div className='col-10'>
                    <span>Scarborough, Pickering, Ajax,</span>
                    <span>Whitby, Markham, Oshawa,</span>
                    <span>Uxbridge</span>
                  </div>
                </div>
              </div>
              
            </ul>
            <div className='social_links text-center text-lg-left mt-5'> 
              <div className="search-box">
                <input type="text" onChange={EmailHandler} value={formData.email} placeholder="Enter Your Email" />
                <button type="submit" onClick={SubscribeHandler} onMouseLeave={() => setBtnHover(false)} onMouseEnter={() => setBtnHover(true)}>
                  <img className='img-fluid' src={!btnHover ? '/images/icons/subs-btn.png' : '/images/icons/subs-btn-golden.png' } />
                </button>
              </div>
              <div className='subscription_social_links mt-4'>
                <a href='https://www.facebook.com/questsportstrackandfieldclub/' target='_blank' rel='noopener noreferrer'><img className='img-fluid' src='/images/icons/facebook.svg' /></a>
                <a href='https://www.instagram.com/questsportstrackandfieldclub/' target='_blank' rel='noopener noreferrer'><img className='img-fluid' src='/images/icons/insta.svg' /></a>
                <a href='https://twitter.com/QuestSportsCAN' target='_blank' rel='noopener noreferrer'><img className='img-fluid' src='/images/icons/twitter.svg' /></a>
                <a href='https://www.tiktok.com/@questsportstrackandfield' target='_blank' rel='noopener noreferrer'><img className='img-fluid' src='/images/icons/tiktok.svg' /></a> 
              </div>
            </div>
          </div>
        </div>
        <hr style={{ borderTop: "1px solid #FFFFFF" }} />
        <div className="row text-center text-lg-start bottom-column-info">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <p>© 2023 Quest Sports. All rights reserved.</p> 
          </div> 
          <div className="col-lg-4 col-md-6 col-sm-12"></div>
          <div className="col-lg-4 col-md-12 col-sm-12"> 
            <div className='d-flex justify-content-center align-items-center'>
              <p>
                <img
                  onMouseEnter={() => handleHover(0)}
                  onMouseLeave={() => handleHover(-1)}
                  className='img-fluid payment-icons' 
                  src={hoveredIndex === 0 ? '/images/icons/gold-visa.svg' : '/images/icons/visa.svg' }
                />
              </p>

              <p>
                <img
                  onMouseEnter={() => handleHover(1)}
                  onMouseLeave={() => handleHover(-1)}
                  className='img-fluid payment-icons' 
                  src={hoveredIndex === 1 ? '/images/icons/golden-stripe.svg' : '/images/icons/stripe.svg' }
                  style={{ marginRight: "10px",  marginLeft: "10px"  }} 
                />
              </p>

              <p>
                <img
                  onMouseEnter={() => handleHover(2)}
                  onMouseLeave={() => handleHover(-1)}
                  className='img-fluid payment-icons' 
                  src={hoveredIndex === 2 ? '/images/icons/golden-paypal.svg' : '/images/icons/paypal.svg' }
                  style={{ marginRight: "10px" }} 
                />
              </p>

              <p>
                <img
                  onMouseEnter={() => handleHover(3)}
                  onMouseLeave={() => handleHover(-1)} 
                  className='img-fluid payment-icons' 
                  src={hoveredIndex === 3 ? '/images/icons/golden-mastercard.svg' : '/images/icons/master-card.svg' }
                  style={{ marginRight: "10px" }} 
                />
              </p>

              <p>
                <img
                  onMouseEnter={() => handleHover(4)}
                  onMouseLeave={() => handleHover(-1)} 
                  className='img-fluid payment-icons' 
                  src={hoveredIndex === 4 ? '/images/icons/golden-amz.svg' : '/images/icons/pay.svg' }
                />
              </p> 
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;